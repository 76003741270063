<template>
    <div class="login-wrapper">
        
        <div class="login-group">
            <h1>Log in</h1>

            <form class="login-form" @submit.prevent="HandleLogin" method="post" action="">
                <Input v-model="login.email" :error="errors.email" name="Email address" inputName="email" placeholder="Email address"/>
                <Input v-model="login.password" :error="errors.password" name="Password" inputName="password" type="password" placeholder="Password"/>
                <Button type="submit" text="Log in" icon="fa-solid fa-arrow-right-to-bracket" />
            </form>

        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                login: {
                    email: '',
                    password: ''
                },
                errors: {}
            }
        },
        methods: {
            HandleLogin() {
                this.ajax('HandleLogin', {
                    url: '/referrals/user/login',
                    method: 'POST',
                    data: this.login
                }, (err, body) => {

                    if(err) {
                        if(body.errors)  this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    if(body.token) {
                        // store token in app store
                        // this.$store.commit('token', body.token);
                        localStorage.setItem("referral-token", body.token);
                        location.reload();
                    } else {
                        this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                    }

                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.login-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login-group {
    max-width: 450px;
    width: 100%;
    background: $themeColor1;
    padding: 30px;
    border-radius: 10px;
    display: grid;
    row-gap: 20px;
}


.login-form {
    display: grid;
    row-gap: 15px;
    justify-items: start;
}
</style>