<template>
    <div class="menu-item" v-click-outside="() => { shown = false }">
        <router-link :to="link.link"  custom  v-slot="{ href, route, navigate, isActive, isExactActive }">
            <a :href="link.link" class="main-link" @click.prevent="handleLinkClick(href, route, navigate)" :class="[{ 'router-link-hover' : shown }, { 'router-link-active' : link.exact ? isExactActive : isActive }]">
                <!-- <span><i :class="link.icon"></i></span> -->
                <span>{{ link.name }}</span>
                <div v-if="link.submenu && link.submenu.length > 0">
                    <span class="icon" v-if="shown"><i class="fa-solid fa-chevron-up"></i></span>
                    <span class="icon" v-else><i class="fa-solid fa-chevron-down"></i></span>
                </div>
            </a>
        </router-link>

        <Transition name="slide-fade">
            <div v-if="shown && link.submenu && link.submenu.length > 0">
                <div class="sublinks">
                    <router-link :to="sub.link" @click="shown = false" :class="[{ 'hasChildren': sub.hasChildren }]" v-for="(sub, key) of link.submenu">
                        <span class="icon"><i :class="sub.icon"></i></span>
                        <span class="name">{{ sub.name }}</span>
                    </router-link>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
    export default {
        props: ['link'],
        data() {
            return {
                shown: false
            }
        },
        methods: {
            handleLinkClick(href, route, navigate) {
                if(!this.link.submenu) {
                    this.$router.push({ path: href });
                    return;
                }
                if(this.link.submenu.length <= 0) {
                    this.$router.push({ path: href });
                    return;
                }
                
                // if(this.shown) {
                //     this.$router.push({ path: href });
                //     this.shown = false;
                //     return;
                // }

                this.shown = !this.shown;
                
            }
        },
    }
</script>

<style lang="scss" scoped>


%nav-link-active {
    background: $bg;
    color: $themeColor2;
    position: relative;
    // &::before {
    //     content: '' !important;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 2px;
    //     background: $themeColor2;
    // }
}

.menu-item {
    position: relative;
    .sublinks {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 20px);
        width: 220px;
        background: $themeColor1;
        padding: 5px;
        border-radius: 9px;
        border: 1px solid $borderColor;
        display: grid;
        gap: 5px;
        a {
            display: flex;
            height: 36px;
            align-items: center;
            padding: 0 10px;
            border-radius: 9px;
            color: rgba(0,0,0,0.5);
            gap: 10px;
            text-transform: capitalize;
            &.router-link-exact-active {
                background: $bg;
                color: $themeColor2;
            }
            &.router-link-active {
                &.hasChildren {
                    background: $bg;
                    color: $themeColor2;
                }
            }
            &:hover {
                color: $themeColor2;
            }
            .icon {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    a.main-link {
        color: #D1D1D1;
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        transition: ease 0.3s;
        height: 34px;
        padding: 0 15px;
        border-radius: 9px;
        .icon {
            font-size: 12px;
        }
        &:hover {
            color: $themeColor2;
            background: $bg;
        }
        &.router-link-active {
            @extend %nav-link-active;
        }
        &.router-link-hover {
            background: $bg;
            color: $themeColor2;
        }
        &.exact-only {
            &.router-link-exact-active {
                background: rgba(0,0,0,0.01);
                color: $themeColor2;
                @extend %nav-link-active;
            }
        }
        
    }
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px) scale(0.7);
  opacity: 0;
}
</style>