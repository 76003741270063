<template>
    <div class="wrapper">
        <div v-if="this.isLoggedIn" class="grid gap-20">

            <div class="referral-nav-bar">
                <div class="brand">
                    <router-link to="/referral"><img src="/images/invoice-logo.svg" alt="99freight"
                            height="34" /></router-link>
                </div>

                <div class="profile-btn">
                    <img :src="DEFAULTS.USER.IMAGE" class="ph-image" width="34" height="34" />
                    <div>
                        <div class="name text-overflow">{{ user.fullName }}</div>
                        <div><a href="" @click.prevent="logout" class="log-out-btn">Log out</a></div>
                    </div>
                </div>
            </div>

            <div class="app grid gap-50">
                <div class="grid gap-20">
                    <div style="display: flex; gap: 10px; flex-wrap: wrap;">
                        <MenuItem :key="key" v-for="(link, key) of menu" :link="link" />
                    </div>
                </div>
                <router-view></router-view>
            </div>
        </div>

        <Login v-else />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useReferralsStore } from '../../store/Referrals';
import Login from './components/Login.vue';

import MenuItem from '../../components/MenuItem.vue'

export default {
    components: {
        Login,
        MenuItem
    },
    data() {
        return {
            menu: [
                {
                    link: "/referral",
                    name: "Dashboard",
                    icon: "fa-solid fa-boxes-stacked",
                    exact: true,
                },
                {
                    link: "/referral/carriers",
                    name: "My Carriers",
                    icon: "fa-solid fa-boxes-stacked",
                    exact: true,
                },
                {
                    link: "/referral/payments",
                    name: "Payments",
                    icon: "fa-solid fa-boxes-stacked",
                    exact: true,
                }
                // { name: 'Dashboard', to: 'ReferralApp' },
                // { name: 'My Carriers', to: 'ReferralAppCarriers' },
                // { name: 'Payments', to: 'ReferralAppPayments' },
                // { name: 'Settings', to: 'ReferralAppSettings' },
            ]
        }
    },
    computed: {
        ...mapState(useReferralsStore, ['isLoggedIn', 'user']),
        ...mapActions(useReferralsStore, ['logout'])
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    min-width: 864px;
    max-width: 1024px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}


.app {
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    padding: 30px;
    border-radius: 10px;
    background: $themeColor1;
    position: relative;
}

.referral-nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand {
    img {
        height: 35px;
    }
}

.profile-btn {
    display: flex;
    gap: 15px;
    align-items: center;
    height: 54px;
    border-radius: 9px;
    padding: 0 15px 0 10px;
    user-select: none;
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;

    .ph-image {
        object-fit: contain;
        width: 34px;
        height: 34px;
        border-radius: 50%;
    }

    .name {
        font-weight: 500;
        max-width: 150px;
    }

    .log-out-btn {
        color: $error;
        font-size: 12px;
    }
}
</style>